import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'




import axios from "axios"
Vue.prototype.$http = axios



axios.interceptors.request.use(async req => {


	if (req.url.indexOf("http") >= 0) {

	} else {
		req.url="//taocanapi.mmteck.cn"+req.url
		
	}
	// if (req.data) req.data.debug = 1
	if (req.data && !req.data.noloading) {
		// $("#loading").show();
	}
	return req
}, error => {
	return Promise.reject(error)
});

function $$parent() {
	let parent = arguments[0];
	let func = arguments[1]
	let para = []
	for (let i = 2; i < arguments.length; i++) {
		para.push(arguments[i])
	}
	return new Promise(resolve => {
		let checkParent = idx => {
			if (parent.$parent && idx < 20) {
				parent = parent.$parent
				if (parent[func] !== undefined) {
					if (typeof (parent[func]) == "function") {
						parent[func](...para)
						resolve(true)
					} else {
						if (arguments.length >= 3) {//判定是赋值
							parent[func] = arguments[2]
							resolve(parent[func])
						} else {//判定是读值
							resolve(parent[func])
						}

					}
				} else {
					checkParent(idx + 1)
				}
			} else {
				console.log("未找到：" + func)
				resolve(undefined)
			}
		}
		checkParent(0)
	}, reject => { })

}

Vue.prototype.$$parent = $$parent

Vue.prototype.$keepTwoDecimal = (num) => {
	var result = parseFloat(num);
	if (isNaN(result)) {
		return num;
	}
	result = Math.round(num * 100) / 100;
	return result;
};


Vue.prototype.isJSON = (str) => {
	if (typeof str == 'string') {
		try {
			var obj = JSON.parse(str);
			if (typeof obj == 'object' && obj) {
				return true;
			} else {
				return false;
			}

		} catch (e) {
			console.log('error：' + str + '!!!' + e);
			return false;
		}
	} else {
		return false;
	}
}

var Request = function (name) {
	var n_url = window.location.href;
	n_url = n_url.replace("/#", "");
	if (n_url.indexOf("?") > 0) {
		n_url = n_url.split("?")[1]
	}
	var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
	var r = n_url.match(reg);
	if (r != null) return decodeURIComponent(r[2]);
	return null;
}
Vue.prototype.$Request = Request

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')

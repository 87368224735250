import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		pmid: window.pmid,
		teacher: {},
		leftmenu: 0,
		smenucount:0,
		config: {
			logo: "",
			leftlogo: "",
			mainbg: "",
			schoolname: "",
			loginbg: "",
			favico: "",
			sync_data: 0
		}
	},
	mutations: {
		setTeacher(state, value) {
			state.teacher = value
		},
		setPmid(state, value) {
			state.pmid = value
		},
		showSmenu(state, value) {
			state.leftmenu = value
		},
		smenuCount(state, value) {
			state.smenucount = value
		},
		setConfig(state, value) {
			state.config = value
		},
	},
	actions: {
		setTeacher(context, value) {
			context.commit("setTeacher", value);
		},
		setPmid(context, value) {
			context.commit("setPmid", value);
		},
		showSmenu(context, value) {
			context.commit("showSmenu", value);
		},
		smenuCount(context, value) {
			context.commit("smenuCount", value);
		},
		setConfig(context, value) {
			context.commit("setConfig", value);
		},
	},
	modules: {},
	getters: {
		isGradeLeader: (state)=> (value) => {
			if (window.pmid == 221) {
				let roles = state.teacher.sys_roles
				let classes = state.teacher.class_names
				if (classes.filter(c => Number(c.class_id) === Number(value)).length) {
					return false
				}
				if (roles && roles.length) {
					if (roles.filter(r => r.role_code == 'njzz').length) {
						return true
					}
				}
			}
			return false
		},
	}
})

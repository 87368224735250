import Vue from 'vue'
import VueRouter from 'vue-router'
import h5 from '../views/h5/index'
Vue.use(VueRouter)

const routes = [
	//手机端的页面放这里
	{
		path: "/h5",
		component: h5,
		children: [
			{
				path: '/taocan',
				component: resolve => require(['../views/h5/taocan.vue'], resolve)
			},
			{
				path: '/cmcc',
				component: resolve => require(['../views/h5/cmcc.vue'], resolve)
			},
			{
				path: '/unicom',
				component: resolve => require(['../views/h5/unicom.vue'], resolve)
			},
			{
				path: '/baohao',
				component: resolve => require(['../views/h5/baohao.vue'], resolve)
			},
			
		]
	}
]



const router = new VueRouter({
	base: process.env.BASE_URL,
	mode:"history",
	routes
})


//获取url参数的方法，支持vue路由各种参数写法
const $request = function (name) {
	var params = [];
	var value = null;
	if (window.location.href.indexOf(name + "=") >= 0) {
		let q = window.location.href.split("?")[1];
		if (q.indexOf("#") > 0) {
			q = q.split("#")[0];
		}
		params = q.split("&");
		for (let p of params) {
			if (p.indexOf(name + "=") >= 0) {
				value = p.replace(name + "=", "").replace(/\//g, "")
				break;
			}
		}
	}
	if (value && value != undefined && value != "undefined") {
		return value
	} else {
		return "";
	}
}


//路由进入之前检查是否登录
// router.beforeEach((to, from, next) => {
// 		next()
	
// })



export default router
